.gridbox {
    display: inline-grid;
    grid-template-columns: 15vw 15vw 15vw 15vw;
    grid-auto-rows: 35vh;
    justify-content: center;
    vertical-align: middle;
    width: 100vw;
    max-width: 100%;
    margin: auto;
    margin-top: 5%;
  }

.griditem p{
    display: block;
    text-align: center;
}

.griditem {
    position: relative;
    display: inline-block;
    align-items: center;
    justify-content: center;
    width: 10vw;
    height: 30vh;
    box-shadow: 5px 10px 8px;
    margin-left: 2.5vw;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .3s ease;
    background-color: black;
    border-radius: 5px;
  }

  .overlay p {
      padding: 20px;
      font-family: "Inter", sans-serif;
      color: white;
      
  }

.addbutton {
    display: block;
    background-color: #CC4BC2;;
    margin: auto;
    width: 50%;
    height: 15%;
    outline: none;
    border: none;
    color: white;
    border-radius: 8px;
    font-size: 1em;
}

.addbutton:hover {
    cursor: pointer;
    transition: .3s ease;
}

.griditem:hover .overlay {
    opacity: 1;
  }

.griditem:hover img{
    opacity: 1;
  }

.poster {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.error {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 40px;
    text-align: center;
}
