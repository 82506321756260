.bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
  }
  
  .bm-item:hover {
    color: white;
  }
  
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }
  
  .bm-burger-bars {
    background: #373a47;
  }
  
  .bm-cross-button {
    margin-top: -50%;
    height: 24px;
    width: 24px;
  }
  
  .bm-cross {
    background: #bdc3c7;
  }
  
  .bm-menu {
    background: #474747;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    margin-top: -50%;
  }
  
  .bm-morph-shape {
    fill: #373a47;
  }
  
  .bm-item-list {
    color: #b8b7ad;
  }
  
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  -webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  -webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }
  -webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }

 .listitem p {
    padding: 20px;
 }

 .listitem {
   display: block;
   text-align: center;
   background-color: #141414;
   border-radius: 7px;
   box-shadow: 5px 5px 5px;
 }

 .deletebutton {
  width: 70px;
  height: 30px;
  padding: 5px;
  background-color: white;
 }

 .copylink {
   color: black;
 }

 .copylink:hover {
   color: black;
 }