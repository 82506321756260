.searchContainer {
    width: 100vw;
    max-width: 100%;
}

.searchbar {
    display: block;
    margin: auto;
    width: 50vw;
    height: 2vh;
    outline: 0;
    padding: 10px;
    font-size: 20px;
    border-radius: 10px;
}
